import Header from "./Header";
import "../stylesheets/News.css";
import Footer from "./Footer";
const News = () => {
  return (
  <div className="news-container">
    <Header />
       Here goes the News page
    <Footer/>
  </div>
);
};

export default News;
