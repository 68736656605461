// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-container {
    width: 300px;
    margin: auto;
    height: auto;
    padding: 20px;
    border: 1px solid #00008B;
    border-radius: 8px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .register-title {
    color: #00008B;
    text-align: center;
  }
  
  .register-input {
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #00008B;
    border-radius: 4px;
  }
  
  .register-button {
    width: 100%;
    padding: 10px;
    background-color: #00008B;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .input-container {
    margin-bottom: 15px; /* Space between input fields */
  }
  
  .error {
    color: red; /* Error text color */
    font-size: 12px; /* Adjust font size for error messages */
  }
  
  `, "",{"version":3,"sources":["webpack://./src/branches/branch1/stylesheets/Register.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,UAAU;IACV,aAAa;IACb,cAAc;IACd,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,mBAAmB,EAAE,+BAA+B;EACtD;;EAEA;IACE,UAAU,EAAE,qBAAqB;IACjC,eAAe,EAAE,wCAAwC;EAC3D","sourcesContent":[".register-container {\n    width: 300px;\n    margin: auto;\n    height: auto;\n    padding: 20px;\n    border: 1px solid #00008B;\n    border-radius: 8px;\n    background-color: #f5f5f5;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .register-title {\n    color: #00008B;\n    text-align: center;\n  }\n  \n  .register-input {\n    width: 90%;\n    padding: 10px;\n    margin: 10px 0;\n    border: 1px solid #00008B;\n    border-radius: 4px;\n  }\n  \n  .register-button {\n    width: 100%;\n    padding: 10px;\n    background-color: #00008B;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .input-container {\n    margin-bottom: 15px; /* Space between input fields */\n  }\n  \n  .error {\n    color: red; /* Error text color */\n    font-size: 12px; /* Adjust font size for error messages */\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
