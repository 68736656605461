import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import About from "./components/About";
import News from "./components/News";

import Home1 from "./branches/branch1/components/Home";
import About1 from "./branches/branch1/components/About";
import Faculties1 from "./branches/branch1/components/Faculties";
import Admissions1 from "./branches/branch1/components/Admissions";
import Gallery1 from  "./branches/branch1/components/Gallery";
import News1 from "./branches/branch1/components/News";
import Contact1 from "./branches/branch1/components/Contact";
import Facilities1 from "./branches/branch1/components/Facilites";
import Alumina1 from "./branches/branch1/components/Alumina";
import Login1 from "./branches/branch1/components/Login";
import Register1 from "./branches/branch1/components/Register";

import Home2 from "./branches/branch2/components/Home";
import Home3 from "./branches/branch3/components/Home";
import Home4 from "./branches/branch4/components/Home";
import Home5 from "./branches/branch5/components/Home";

function App() {
  return (
    <div>
      <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/about" element={<About />} />
            <Route path="/news" element={<News />} />
            <Route path="/gallery" element={<Gallery />} />

            {/* branch 1 */}
            <Route path="/sandeepanihitechschool" element={<Home1 />} />
            <Route path="/sandeepanihitechschool/gallery" element={<Gallery1 />} />
            <Route path="/sandeepanihitechschool/news" element={<News1 />} />
            <Route path="/sandeepanihitechschool/contact" element={<Contact1 />} />
            <Route path="/sandeepanihitechschool/about" element={<About1 />} />
            <Route path="/sandeepanihitechschool/faculties" element={<Faculties1 />} />
            <Route path="/sandeepanihitechschool/facilities" element={<Facilities1 />} />
            <Route path="/sandeepanihitechschool/alumina" element={<Alumina1 />} />
            <Route path="/sandeepanihitechschool/admissions" element={<Admissions1 />} />
            <Route path="/sandeepanihitechschool/login" element={<Login1 />} />
            <Route path="/sandeepanihitechschool/registration" element={<Register1 />} />

            <Route path="/branch2" element={<Home2 />} />
            <Route path="/branch3" element={<Home3 />} />
            <Route path="/branch4" element={<Home4 />} />
            <Route path="/branch5" element={<Home5 />} />

          </Routes>
      </Router>
    </div>
  );
}

export default App;
