import { useNavigate } from "react-router-dom";
import "../stylesheets/Header.css";
import Logo from "../resources/header/main logo.png";
const Header = () => {
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="header1">
      <div className="logo-title">
        <div className="logo">
          <img
            src={Logo}
            alt="logo"
            style={{ width: "114px", height: "114px" }}
          />
        </div>
        <div className="school-name">Sandeepani Group of Institutions</div>
      </div>
      <div className="nav-buttons">
        <button className="button" onClick={() => navigateTo("/")}>
          Home
        </button>
        <button className="button" onClick={() => navigateTo("/about")}>
          About Us
        </button>
        <button className="button" onClick={() => navigateTo("/gallery")}>
          Gallery
        </button>
        {/* <button className="button" onClick={() => navigateTo("/news")}>
          News
        </button> */}
        <button className="button" onClick={() => navigateTo("/contact")}>
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default Header;
