import Header from "./Header";
import "../stylesheets/Home.css";

const Home = () => {
  return (
  <div>
    <Header />
    Here goes the Home page for branch4
  </div>
);
};

export default Home;