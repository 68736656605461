import Header from "./Header";
import "../stylesheets/Faculties.css";
import Footer from "./Footer";
const Faculties = () => {
  return (
    <div>
      <Header />
         Here goes the Admission page
      <Footer />
    </div>
  );
};

export default Faculties;
