// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5; 
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
  gap: 15px;
  width: 100%; 
  max-width: 1200px; 
}

.grid-item {
  width: 100%;
  height: auto;
  border-radius: 10px; 
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); 
  transition: transform 0.3s, box-shadow 0.3s; 
  cursor: pointer; 
}

.grid-item:hover {
  transform: scale(1.05); 
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4); 
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px; 
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);
}

@media (max-width: 768px) {
  .grid-item {
    margin: 0; 
  }
}
`, "",{"version":3,"sources":["webpack://./src/stylesheets/Gallery.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;EACT,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,yCAAyC;EACzC,2CAA2C;EAC3C,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,yCAAyC;AAC3C;;AAEA,iBAAiB;AACjB;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,cAAc;EACd,eAAe;EACf,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE;IACE,SAAS;EACX;AACF","sourcesContent":[".gallery-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  background-color: #f5f5f5; \n}\n\n.grid-container {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); \n  gap: 15px;\n  width: 100%; \n  max-width: 1200px; \n}\n\n.grid-item {\n  width: 100%;\n  height: auto;\n  border-radius: 10px; \n  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); \n  transition: transform 0.3s, box-shadow 0.3s; \n  cursor: pointer; \n}\n\n.grid-item:hover {\n  transform: scale(1.05); \n  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4); \n}\n\n/* Modal styles */\n.modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.8); \n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: opacity 0.5s ease;\n}\n\n.modal-image {\n  max-width: 90%;\n  max-height: 90%;\n  border-radius: 10px; \n  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.4);\n}\n\n@media (max-width: 768px) {\n  .grid-item {\n    margin: 0; \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
