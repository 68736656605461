
import { useNavigate } from "react-router-dom";
import "../stylesheets/Header.css";

const Header = () => {
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  return (

    <div className="header">
      <div className="school-name">Sandeepani Indian International Public School, Hosadurga</div>
      <div className="nav-buttons">
        <button className="button" onClick={() => navigateTo("/")}>
          Home
        </button>
        <button className="button" onClick={() => navigateTo("/about")}>
          About Us
        </button>
        <button className="button" onClick={() => navigateTo("/gallery")}>
          Gallery
        </button>
        <button className="button" onClick={() => navigateTo("/news")}>
          News
        </button>
        <button className="button" onClick={() => navigateTo("/contact")}>
          Contact Us
        </button>
        
      </div>
    </div> 
  );
};

export default Header;
