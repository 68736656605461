import React, { useState } from "react";
import Header from "./Header";
import "../stylesheets/Gallery.css";
import Footer from "./Footer";
import school1 from "../resources/images/school1.png";
import school2 from "../resources/images/school2.png";
import school3 from "../resources/images/school3.png";
import school4 from "../resources/images/school4.png";
import bus1 from "../resources/images/school_hori5.png";
import stud1 from "../resources/images/school_stud5.png";
import school_vert from "../resources/images/school_vertical.png";
import school_flag from "../resources/images/school6_flag.png";
import stud_flag from "../resources/images/school7_stud_flag.png";
import bus2 from "../resources/images/school8_verti_bus.png";
import map from "../resources/images/school9_vertical_india.png";

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    { src: school1, alt: "School 1" },
    { src: school2, alt: "School 2" },
    { src: school3, alt: "School 3" },
    { src: school4, alt: "School 4" },
    { src: bus1, alt: "School Bus 1" },
    { src: stud1, alt: "Student 1" },
    { src: school_vert, alt: "School rainbow" },
    { src: school_flag, alt: "School with Indian flag" },
    { src: stud_flag, alt: "Student with Indian flag" },
    { src: bus2, alt: "School Bus 2" },
    { src: map, alt: "Indian Map" },
  ];
  console.log(images);

  const openModal = (src) => {
    setSelectedImage(src);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <Header />
      <div className="gallery-container">
        <div className="grid-container">
          {images.map((image, index) => (
            <img
              key={index}
              className="grid-item"
              src={image.src}
              alt={image.alt}
              onClick={() => openModal(image.src)}
            />
          ))}
        </div>

        {/* Modal for larger image view */}
        {selectedImage && (
          <div className="modal" onClick={closeModal}>
            <img className="modal-image" src={selectedImage} alt="Selected" />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
