import Header from "./Header";
import Footer from "./Footer";
import "../stylesheets/Admissions.css";
const Admissions = () => {
  return (
  <div>
    <Header />
    Here goes the Admission page
    <Footer />
  </div>
);
};

export default Admissions;
