import Header from "./Header";
import "../stylesheets/Gallery.css";
import Footer from "./Footer";
const Gallery = () => {
  return (
  <div>
    <Header />
    Here goes the Gallery page
    <Footer />
  </div>
);
};

export default Gallery;