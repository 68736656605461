import Header from "./Header";
import Footer from "./Footer";
import "../stylesheets/News.css";
const News = () => {
  return (
    <div>
      <Header />
      Here goes the News page  
      <Footer />
    </div>
  );
};

export default News;
