import Header from "./Header";
import Footer from "./Footer";
import "../stylesheets/Home.css";

const Home = () => {
  return (
    <div>
      <div className="home1-container"></div>
      <Header />
      here goes Home page
      <Footer />
    </div>
  );
};

export default Home;
