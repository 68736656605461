// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-arrow {
    position: fixed;
    bottom: 20px; 
    right: 20px; 
    padding: 20px;
    background-color: #00008BA8; 
    color: white;
    border-radius: 60%;
    cursor: pointer;
    font-size: 14px; 
    z-index: 1000; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .home-arrow:hover {
    background-color: #00008B;
  }
  `, "",{"version":3,"sources":["webpack://./src/utilities/UpArrow.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;IACb,2BAA2B;IAC3B,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,aAAa;IACb,0CAA0C;IAC1C,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".home-arrow {\n    position: fixed;\n    bottom: 20px; \n    right: 20px; \n    padding: 20px;\n    background-color: #00008BA8; \n    color: white;\n    border-radius: 60%;\n    cursor: pointer;\n    font-size: 14px; \n    z-index: 1000; \n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n    transition: background-color 0.3s ease;\n  }\n  \n  .home-arrow:hover {\n    background-color: #00008B;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
