// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: #00008BA8;
    color: white;
    text-align: left; 
    border-top: 3px solid white;
    margin-top: auto;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    margin: 0 5%;
    align-items: flex-start;
}

.footer-left {
    max-width: 50%; 
}

.footer-right {
    text-align: right;
}

.school-name {
    font-family: inter;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 10px;
}
.ft-title{
    font-family: inter;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 10px;
    margin-top: 20px;
}
.caption {
    font-size: 16px;
}



@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        text-align: center;
    }

    .footer-left, .footer-right {
        max-width: 100%;
    }

    .footer-right {
        text-align: center; 
    }
}
`, "",{"version":3,"sources":["webpack://./src/stylesheets/Footer.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,YAAY;IACZ,gBAAgB;IAChB,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB;;;;AAIA;IACI;QACI,sBAAsB;QACtB,kBAAkB;IACtB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".footer {\n    background-color: #00008BA8;\n    color: white;\n    text-align: left; \n    border-top: 3px solid white;\n    margin-top: auto;\n}\n\n.footer-content {\n    display: flex;\n    justify-content: space-between;\n    margin: 0 5%;\n    align-items: flex-start;\n}\n\n.footer-left {\n    max-width: 50%; \n}\n\n.footer-right {\n    text-align: right;\n}\n\n.school-name {\n    font-family: inter;\n    font-size: 24px;\n    font-weight: 800;\n    margin-bottom: 10px;\n}\n.ft-title{\n    font-family: inter;\n    font-size: 24px;\n    font-weight: 800;\n    margin-bottom: 10px;\n    margin-top: 20px;\n}\n.caption {\n    font-size: 16px;\n}\n\n\n\n@media (max-width: 768px) {\n    .footer-content {\n        flex-direction: column;\n        text-align: center;\n    }\n\n    .footer-left, .footer-right {\n        max-width: 100%;\n    }\n\n    .footer-right {\n        text-align: center; \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
